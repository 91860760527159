

















































import dayjs from 'dayjs';
import _ from 'lodash';
import Vue from 'vue';
import DataTable from '@/components/generic/DataTable.vue';
import DataTableItemFormattedCurrency from '@/components/generic/DataTableItemFormattedCurrency.vue';
import DataTableItemFormattedNumber from '@/components/generic/DataTableItemFormattedNumber.vue';
import DataTableItemWrap from '@/components/generic/DataTableItemWrap.vue';
import PageTitle from '@/components/specific/PageTitle.vue';
import stripeTimestampToDate from '@/resources/functions/stripeTimestampToDate';
import Log from '@/resources/plugins/Logger/Log';
import ServiceFactory from '@/services/ui/ServiceFactory';
import store from '@/store';
import { UICommonMapper, UICommon } from '@/store/modules/ui/common';
import type { DataTableHeader } from 'vuetify';

type BillingMonth = {
  text: string;
  value: string;
};

type InvoiceItem = {
  nickname: string;
  unitAmount: number | undefined;
  quantity: number | undefined;
  amount: number;
};

const InvoiceService = ServiceFactory.get('invoice');

export default Vue.extend({
  name: 'StatementPage',

  components: {
    DataTable,
    DataTableItemFormattedCurrency,
    DataTableItemFormattedNumber,
    DataTableItemWrap,
    PageTitle,
  },

  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  data(): {
    billingMonths: BillingMonth[];
    invoice: {
      status: string;
      subTotal: number;
      tax: number;
      taxRate: number;
      taxInclusive: boolean;
      total: number;
    };
    invoiceItemTable: {
      headers: DataTableHeader[];
      items: InvoiceItem[];
      loading: boolean;
    };
    selectedBillingMonth: BillingMonth;
  } {
    return {
      billingMonths: [],
      invoice: {
        status: '',
        subTotal: 0,
        tax: 0,
        taxInclusive: false,
        taxRate: 0,
        total: 0,
      },
      invoiceItemTable: {
        headers: [
          {
            sortable: false,
            text: '摘要',
            value: 'nickname',
          },
          {
            align: 'end',
            sortable: false,
            text: '単価',
            value: 'unitAmount',
          },
          {
            align: 'end',
            sortable: false,
            text: '数量',
            value: 'quantity',
          },
          {
            align: 'end',
            sortable: false,
            text: '合計',
            value: 'amount',
          },
        ],
        items: [],
        loading: false,
      },
      selectedBillingMonth: {
        text: '',
        value: '',
      },
    };
  },

  computed: {
    paid(): boolean {
      return this.invoice.status === 'paid';
    },
  },

  watch: {
    selectedBillingMonth() {
      this.getInvoice();
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const procedures = [
        // 次回請求予定（必ず存在する。また、invoice_idはない。）
        InvoiceService.getInvoiceUpcoming(),
        // 作成済みの請求書
        InvoiceService.getInvoiceIds(),
      ];
      const [responseUpcoming, responseInvoiceIds] = await Promise.all(procedures);

      let invoiceIds = _.get(responseInvoiceIds, 'invoice_ids', []);

      // 最古のインボイスが0円かつ支払い済みならリストから除外
      if (invoiceIds.length) {
        const firstInvoiceId = invoiceIds[invoiceIds.length - 1];
        const response = await InvoiceService.getInvoice(firstInvoiceId.invoice_id);
        if (response.total === 0 && response.status === 'paid') {
          invoiceIds = _.dropRight(invoiceIds);
        }
      }

      invoiceIds.unshift({
        // APIによりパラメータ名が定義されているため除外
        /* eslint-disable @typescript-eslint/camelcase */
        invoice_id: 'upcoming',
        period_start: stripeTimestampToDate(responseUpcoming.period_start),
        /* eslint-enable @typescript-eslint/camelcase */
      });

      const billingMonths: BillingMonth[] = [];

      for (let i = 0; i < invoiceIds.length; i += 1) {
        // 最新の明細は請求予定
        if (i === 0) {
          billingMonths.push({
            text: dayjs(invoiceIds[i].period_start).format('YYYY年M月'),
            value: 'upcoming',
          });
        } else {
          billingMonths.push({
            text: dayjs(invoiceIds[i].period_start).format('YYYY年M月'),
            value: invoiceIds[i].invoice_id,
          });
        }
      }

      // 型が不明のためanyを許容
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next((vm: any) => {
        // コンポーネントにデータを渡すために代入が必要
        /* eslint-disable no-param-reassign */
        vm.billingMonths = billingMonths;
        [vm.selectedBillingMonth] = billingMonths;
        /* eslint-enable no-param-reassign */
      });
    } catch (error) {
      Log.error(error);

      const uiCommonContext = UICommon.context(store);
      const { setErrorMessage, setNavigating } = uiCommonContext.actions;
      setErrorMessage({ text: error.message });
      setNavigating({ navigating: false });

      next(false);
    }
  },

  mounted() {
    const appMain = document.getElementById('app-main');

    if (appMain !== null) {
      appMain.scrollIntoView();
    }
  },

  methods: {
    ...UICommonMapper.mapActions(['setErrorMessage']),

    generateItemClasses(item: InvoiceItem) {
      if (item.nickname === '小計' || item.nickname === '合計') {
        return 'font-weight-bold';
      }
      return '';
    },

    async getInvoice() {
      const self = this;

      self.invoiceItemTable.loading = true;

      try {
        let response;

        if (self.selectedBillingMonth.value === 'upcoming') {
          response = await InvoiceService.getInvoiceUpcoming();
        } else {
          response = await InvoiceService.getInvoice(self.selectedBillingMonth.value);
        }

        self.invoice.total = response.total;
        self.invoice.subTotal = response.subtotal;
        self.invoice.tax = response.tax;
        self.invoice.taxRate = response.default_tax_rates[0].percentage;
        self.invoice.taxInclusive = response.total_tax_amounts[0].inclusive;
        self.invoice.status = response.status;

        self.invoiceItemTable.items = [];

        // 明細
        _.forEach(response.lines.data, (lineItem) => {
          self.invoiceItemTable.items.push({
            amount: lineItem.amount,
            nickname: lineItem.price.nickname,
            quantity: lineItem.quantity,
            unitAmount: lineItem.price.unit_amount,
          });
        });

        // 小計
        self.invoiceItemTable.items.push({
          amount: self.invoice.subTotal,
          nickname: '小計',
          quantity: undefined,
          unitAmount: undefined,
        });

        // 消費税
        self.invoiceItemTable.items.push({
          amount: self.invoice.tax,
          nickname: self.invoice.taxInclusive
            ? `消費税（内税）${self.invoice.taxRate}％`
            : `消費税（外税）${self.invoice.taxRate}％`,
          quantity: undefined,
          unitAmount: undefined,
        });

        // 合計
        self.invoiceItemTable.items.push({
          amount: response.total,
          nickname: '合計',
          quantity: undefined,
          unitAmount: undefined,
        });

        self.invoiceItemTable.loading = false;
      } catch (error) {
        self.invoiceItemTable.loading = false;
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },
  },
});
